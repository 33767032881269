import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@emotion/react';
import reportWebVitals from './reportWebVitals';

import SessionContextProvider from './context/SessionContext';
import GeneralContextProvider from './context/GeneralContext';
import { mediaBucket } from './constants';
import App from './containers/App';

import theme from './theme';
import './index.module.scss';
import 'normalize.css';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito User Pool ID

    userPoolId: 'us-east-2_RbrBsfCgO',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '3avpavbo545c1uss2iii84n6nn',

    identityPoolId: 'us-east-2:906ebffc-b8ec-416b-9acf-15d197c60203',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Hosted UI configuration
    /* oauth: {
        domain: 'your_cognito_domain',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    } */
  },
  Storage: {
    AWSS3: mediaBucket,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <SessionContextProvider>
      <GeneralContextProvider>
        <App />
      </GeneralContextProvider>
    </SessionContextProvider>
  </ThemeProvider>,
);

reportWebVitals();
