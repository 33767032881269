export const referenceKey = 'referenceByKinsurer';
export const sessionKey = 'kinsurer-user';
export const quotationPullKey = 'quotation_pull';

export const mediaBucket = {
  bucket: 'kinsu-media',
  region: 'us-east-2',
};

// ---------- DEVELOP -------------

export const urlBase =
  'https://nhklfizas1.execute-api.us-east-2.amazonaws.com/develop';
export const branch = 'develop';
export const kinsurerDocsBucket = {
  bucket: 'kinsu-documents-profile-develop',
  region: 'us-east-2',
};
export const reportsBucket = {
  bucket: 'cross-processing-develop',
  region: 'us-east-2',
};

// ---------- PRODUCTION ----------

// export const urlBase =
//   'https://k3iuhdqnlf.execute-api.us-east-2.amazonaws.com/production';
// export const branch = 'production';
// export const kinsurerDocsBucket = {
//   bucket: 'kinsu-documents-profile-production',
//   region: 'us-east-2',
// };
// export const reportsBucket = {
//   bucket: 'cross-processing-production',
//   region: 'us-east-2',
// };

export const sections = {
  session: ['jarvis-crew'],
  kinsurers: ['admin-crew', 'kinsurers-crew'],
  leads: ['admin-crew', 'leads-crew'],
  dynamicContent: ['admin-crew', 'contents-crew'],
  notifications: ['admin-crew', 'notifications-crew'],
  multimedia: ['admin-crew', 'multimedia-crew'],
  reports: ['admin-crew', 'reports-crew'],
  homologate: ['admin-crew', 'homologate-crew'],
};
